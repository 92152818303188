import React, { useEffect, useState } from "react";
import {
  GlobalAlert,
  GlobalButtonStyled,
  GlobalTextField,
  ThemePaddingSpacing,
} from "../../utils/globalStyles";
import axios from "axios";
import { validateRegistrationForm } from "../../utils/helpers/loginValidation";
import Dashboard from "../dashboard/Dashboard";
import { Container, Grid } from "@mui/material";
import {
  RegisterContainer,
  RegisterInner,
  RegisterOuter,
} from "./styles/registerStyles";
import FormFooter from "../formFooter/FormFooter";
import DisplayLogo from "../logo/DisplayLogo";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Video from "../../assets/Atomic-Arcade-Bg.mp4";
import { HomeVideo } from "../home/styles/homeStyles";

function Register({ user }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
  const [currentState, setCurrentState] = useState<string>("");

  useEffect(() => {
    if (user) navigate("/games");
  }, [user]);

  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    cPassword: "",
  });

  const [errors, setErrors] = useState({
    required: "",
    email: "",
    username: "",
    password: "",
    cPassword: "",
    username_exists: false,
    email_exists: false,
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async (e: any) => {
    e.preventDefault();

    // Validate form data before proceeding
    if (!validateRegistrationForm(formData, setErrors)) {
      return;
    }

    // Reset errors state before making the request
    setErrors({
      required: "",
      email: "",
      username: "",
      password: "",
      cPassword: "",
      username_exists: false,
      email_exists: false,
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/register`,
        formData,
      );

      if (response.status === 201) {
        setRegistrationSuccessful(true);
        setFormData({
          email: "",
          username: "",
          password: "",
          cPassword: "",
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (err: any) {
      console.error("Error during registration:", err);

      // Assuming the error structure is consistent and contains an 'error' field
      if (err.response && err.response.data && err.response.data.error) {
        if (err.response.data.error === "email_exists") {
          setErrors((prevState) => ({ ...prevState, email_exists: true }));
        }
        if (err.response.data.error === "username_exists") {
          setErrors((prevState) => ({ ...prevState, username_exists: true }));
        }
      } else {
        // General error handling if the error structure is different
        console.error("An unexpected error occurred:", err);
      }
    }
  };

  //if (loading) return null;

  return (
    <ThemePaddingSpacing style={{ padding: "4rem 0" }}>
      <HomeVideo src={Video} autoPlay={true} loop muted disablePictureInPicture>
        <source src={Video} type="video/mp4" />
      </HomeVideo>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <RegisterOuter>
              <DisplayLogo />
              <RegisterContainer>
                <RegisterInner>
                  {registrationSuccessful && (
                    <GlobalAlert
                      severity="success"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        Registration successful! Redirecting to login...
                      </p>
                    </GlobalAlert>
                  )}
                  {errors.required && (
                    <GlobalAlert
                      severity="error"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>{errors.required}</p>
                    </GlobalAlert>
                  )}
                  {errors.email && !errors.required && (
                    <GlobalAlert
                      severity="error"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>{errors.email}</p>
                    </GlobalAlert>
                  )}
                  {errors.email_exists && (
                    <GlobalAlert
                      severity="error"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>Email is already registered</p>
                    </GlobalAlert>
                  )}
                  {errors.username_exists && (
                    <GlobalAlert
                      severity="error"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        Username is already registered
                      </p>
                    </GlobalAlert>
                  )}
                  {errors.cPassword && (
                    <GlobalAlert
                      severity="error"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p style={{ margin: 0 }}>{errors.cPassword}</p>
                    </GlobalAlert>
                  )}
                  <form
                    onSubmit={(e) => {
                      handleSignUp(e);
                    }}
                  >
                    <GlobalTextField
                      variant="outlined"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email ? formData.email : ""}
                      onChange={handleChange}
                      className={errors.email ? "form-error" : ""}
                    />
                    <GlobalTextField
                      variant="outlined"
                      placeholder="Username"
                      name="username"
                      className={errors.username ? "form-error" : ""}
                      value={formData.username ? formData.username : ""}
                      onChange={handleChange}
                    />
                    <GlobalTextField
                      variant="outlined"
                      type="password"
                      placeholder="Password"
                      name="password"
                      className={errors.password ? "form-error" : ""}
                      value={formData.password ? formData.password : ""}
                      onChange={handleChange}
                    />
                    <GlobalTextField
                      variant="outlined"
                      type="password"
                      placeholder="Confirm Password"
                      name="cPassword"
                      className={errors.password ? "form-error" : ""}
                      value={formData.cPassword ? formData.cPassword : ""}
                      onChange={handleChange}
                    />
                    <GlobalAlert
                      severity="warning"
                      style={{ textAlign: "left", marginBottom: "0.5rem" }}
                    >
                      We will not be able to recover your password, and do not offer password recovery services due to the nature of cryptocurrency services.
                    </GlobalAlert>
                    <GlobalButtonStyled
                      type="submit"
                      variant="contained"
                      style={{ width: "100%" }}
                      onClick={(e) => {
                        handleSignUp(e);
                      }}
                    >
                      Sign up
                    </GlobalButtonStyled>
                  </form>
                  <FormFooter />
                </RegisterInner>
              </RegisterContainer>
            </RegisterOuter>
          </Grid>
        </Grid>
      </Container>
    </ThemePaddingSpacing>
  );
}

export default Register;
